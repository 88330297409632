import React, { useEffect, useState } from "react";

//Components
import TopComponent from "./TopComponent";
import StandaloneQuestion from "./StandaloneQuestion";

//Helper
import { groupDataBasedOnPosition } from "../helpers/utilities";

//Questions
import { testQuestions } from "../data/testQuestions";

//Lenght - Maximum number of questions loaded at the same time
const maxPositions = testQuestions.length - 3;
const disableTime = 420;

const Questionnaire = ({
  setStage,
  saveData,
  userHasBestPlan,
  uploadStatus,
}) => {
  const [screen, setScreen] = useState(0);

  //For the carousel
  const [position, setPosition] = useState(0);
  const [groupedData, setGroupedData] = useState([]);

  //Animation direction
  const [topToBottom, setTopToBottom] = useState(true);

  //For when an animation is executed
  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  useEffect(() => {
    setGroupedData(groupDataBasedOnPosition(testQuestions, position));
  }, [position]);

  const goForward = () => {
    if (screen < maxPositions) {
      setTopToBottom(true);
      setScreen(screen + 1);

      if (position < maxPositions) {
        setPosition(position + 1);
        setDisabled(true);

        setTimeout(() => {
          setDisabled(false);
        }, disableTime);
      }
    } else {
      setError(false);

      if (userHasBestPlan) {
        setStage(2);
      } else {
        setLoading(true);

        saveData();
      }
    }
  };
  const goBack = () => {
    if (screen > 0) {
      setTopToBottom(false);
      setScreen(screen - 1);

      if (position > 0) {
        setPosition(position - 1);
        setDisabled(true);

        setTimeout(() => {
          setDisabled(false);
        }, disableTime);
      }
    }
  };

  useEffect(() => {
    if (uploadStatus === 1) {
      setLoading(false);
      setStage(2);
    }

    if (uploadStatus === 2) {
      setLoading(false);
      setError(true);
    }

    //eslint-disable-next-line
  }, [uploadStatus]);

  return (
    <TopComponent>
      {groupedData.map((question, index) => {
        if (question !== null) {
          return (
            <StandaloneQuestion
              key={question.id}
              index={index}
              question={question}
              goForward={goForward}
              goBack={goBack}
              topToBottom={topToBottom}
              disabled={disabled}
              loading={loading}
              error={error}
              screen={screen}
            />
          );
        } else {
          return <div key={"void " + index}></div>;
        }
      })}
    </TopComponent>
  );
};

export default Questionnaire;
