import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "wouter";
import { ReactComponent as Logo } from "../images/logo-digi.svg";

const Instructions = ({ screen, goForward, index = null, goPrevious }) => {
  const thisElement = useRef(null);
  const [height, setHeight] = useState(568);
  const [location, setLocation] = useLocation();
  const [countdown, setCountdown] = useState(15);

  useEffect(() => {
    setHeight(thisElement.current.clientHeight);
    setTimeout(() => {
      setHeight(thisElement.current.clientHeight);
    }, 100);
  }, [thisElement]);

  useEffect(() => {
    if (index === 2) {
      console.log("Iniciando el contador...");
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            redirectRegister();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [index]);

  const redirectRegister = () => {
    const fullUrl = `https://app.ispeak.team/register${location}`;
    window.location.href = fullUrl;
  };

  return (
    <div
      className="screen instructions"
      style={{
        transform: `translateY(calc(${
          (index !== null
            ? index === 0
              ? screen
              : index === 1
              ? screen - 2
              : screen
            : screen - 1) * -1
        } * ${height}px))`,
        zIndex:
          index === null
            ? screen === 1
              ? 100
              : 0
            : index === 0
            ? index === screen
              ? 100
              : 0
            : index + 1 === screen
            ? 100
            : 0,
      }}
      ref={thisElement}
    >
      <div className="top-container">
        {index === 1 && (
          <div className="back-icon-container" onClick={goPrevious}>
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
        )}
        <Logo className="logo" />

        {index === null ? (
          <p>
            Ahora vienen las preguntas, responde a cada una{" "}
            <strong>SIN el uso de ningún traductor!</strong>
          </p>
        ) : index === 0 ? (
          <p>
            Bravo! Has terminado el cuestionario, pero aún no acabamos, a
            continuación deberás seleccionar una fecha para tu cita diagnóstico
            con un <strong>profesor en vivo.</strong>
          </p>
        ) : index === 1 ? (
          <div className="text">
            <p>
              ¡Enhorabuena! Ya has agendado tu entrevista en vivo. Hemos enviado
              a tu correo la confirmación de esta reunión.
            </p>
            <p>
              Los resultados del «Test teórico» se enviarán a tu correo junto al
              link de registro para que crees tu cuenta.
            </p>
          </div>
        ) : index === 2 ? ( // Ajusta esto para la sección final
          <div className="text">
            <p>Bravo! Has completado todo.</p>
            <p>
              Los resultados del cuestionario se enviarán a tu{" "}
              <strong>correo</strong>, no te olvides de revisarlo! en caso de no
              ver tus resultados, recuerda tambien revisar la carpeta de spam
            </p>
            <p>Serás redirigido en {countdown} segundos...</p>
            <button
              tabIndex={screen === 0 ? -1 : 4}
              className="button blue"
              onClick={() => {
                setCountdown(0);
              }}
            >
              Continuar
            </button>
          </div>
        ) : null}

        {(index === null || index === 0) && (
          <button
            className="button"
            tabIndex={
              index === null
                ? screen === 1
                  ? 0
                  : -1
                : screen === index
                ? 0
                : -1
            }
            onClick={() => {
              goForward();
            }}
          >
            Continuar
          </button>
        )}
      </div>
    </div>
  );
};

export default Instructions;
