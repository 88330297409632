import React, { useEffect, useState } from "react"

//Components
import TopComponent from "./TopComponent"
import Instructions from "./Instructions"
import DatePickerContainer from "./DatePickerContainer"

const screenLimit = 2

const Scheduler = ({
     userHasBestPlan,
     date,
     setDate,
     saveData,
     uploadStatus,
     score,
     setUploadStatus,
     getScore,
}) => {
     const [screen, setScreen] = useState(0)

     const [loading, setLoading] = useState(false)
     const [error, setError] = useState(false)

     const goForward = () => {
          if (screen < screenLimit) {
               if (screen === 1) {
                    setLoading(true)
               } else {
                    setScreen(screen + 1)
               }
          } else {
               alert("You have reached the end")
          }
     }

     const goPrevious = () => {
          setScreen(1)
          setUploadStatus(0)
     }

     useEffect(() => {
          if (uploadStatus === 1) {
               setLoading(false)

               if (screen === 1) {
                    setScreen(screen + 1)
               }
          } else if (uploadStatus === 2) {
               setLoading(false)
               setError(true)
          }

          //eslint-disable-next-line
     }, [uploadStatus])

     useEffect(() => {
          getScore()
     }, [])

     useEffect(() => {
          saveData()
     }, [])

     return (
          <TopComponent>
               {userHasBestPlan ? (
                    <>
                         <Instructions screen={screen} goForward={goForward} index={0} />
                         <DatePickerContainer
                              score={score}
                              screen={screen}
                              goForward={goForward}
                              date={date}
                              setDate={setDate}
                              loading={loading}
                              error={error}
                         />
                         <Instructions
                              screen={screen}
                              goPrevious={goPrevious}
                              goForward={goForward}
                              index={1}
                         />
                    </>
               ) : (
                    <Instructions screen={screen} goForward={goForward} index={2} />
               )}
          </TopComponent>
     )
}

export default Scheduler
