// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
     apiKey: "AIzaSyDdWi9W_IdsYAzlQuHdB-yQhafQZ97rFH0",
     authDomain: "ispeak-f31e1.firebaseapp.com",
     projectId: "ispeak-f31e1",
     storageBucket: "ispeak-f31e1.appspot.com",
     messagingSenderId: "786396996822",
     appId: "1:786396996822:web:be9bcaca0e5fa34dfe1577",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const db = getFirestore(app);