export const diagnosisQuestion = {
  id: 0,
  type: "radio",
  question:
    "Basándote en los perfiles mostrados, ¿Qué nivel de inglés conversacional crees tener?",
  options: [
    { id: 1, text: "Junior", points: 0 },
    { id: 2, text: "Middle", points: 1 },
    { id: 3, text: "Senior", points: 3 },
    { id: 4, text: "Expert", points: 5 },
  ],
};

export const testQuestions = [
  null,
  {
    id: 1,
    type: "radio",
    question:
      "In the Human Resources Department, they all ____ three languages",
    options: [
      { id: 1, text: "speaks" },
      { id: 2, text: "are speaking" },
      { id: 3, text: "speak" },
    ],
    answer: 3,
    points: 2,
  },
  {
    id: 2,
    question:
      "Our sales rep ____ very stubborn. He ____ want to accept our new conditions",
    options: [
      { id: 1, text: "is / does not" },
      { id: 2, text: "are / does" },
      { id: 3, text: "are / do" },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 3,
    question: "The train journey is about one hour, but it ____",
    options: [
      { id: 1, text: "often takes longer." },
      { id: 2, text: "takes often longer." },
      { id: 3, text: "longer often takes." },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 4,
    question: "How often ____ meetings?",
    options: [
      { id: 1, text: "does you have" },
      { id: 2, text: "do you have" },
      { id: 3, text: "does you has" },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 5,
    question: "____ abroad on business every month.",
    options: [
      { id: 1, text: "He travels" },
      { id: 2, text: "He travel" },
      { id: 3, text: "He is travelling" },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 6,
    question: `Please, send me information ____ your product range. \n
      We are interested ____ your range of men's leisurewear and clothes. \n
      Also where are you located? ____ your webpage this is not clear. \n
      Bye for now.`,
    options: [
      { id: 1, text: "about / in / on" },
      { id: 2, text: "of / on / in" },
      { id: 3, text: "on / about / at" },
    ],
    answer: 1,
    points: 4,
  },
  {
    id: 7,
    question: "Do you have ____ advice for me?",
    options: [
      { id: 1, text: "many" },
      { id: 2, text: "much" },
      { id: 3, text: "some" },
    ],
    answer: 3,
    points: 2,
  },
  {
    id: 8,
    question:
      "At the office, we have a ____ of chairs but we don't have ____ tables.",
    options: [
      { id: 1, text: "little / any" },
      { id: 2, text: "lot / many" },
      { id: 3, text: "lot / much" },
    ],
    answer: 2,
    points: 3,
  },
  {
    id: 9,
    question:
      "What an exhausting day! I'm really tired. I'm going to go ____ home and go to ____ bed early.",
    options: [
      { id: 1, text: "no article / no article" },
      { id: 2, text: "in / on" },
      { id: 3, text: "the / the" },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 10,
    type: "video",
    video_URL: "https://www.youtube.com/embed/naIkpQ_cIt0",
    question: `The candidate’s name is ____. \n
      She is applying for ____. \n
      The candidate ____ work experience. \n
      The candidate is very ____. \n
      The candidate can work from ____.`,
    options: [
      {
        id: 1,
        text: "Mary / a kitchen job / doesn’t have / organized / 8am to 5pm",
      },
      {
        id: 2,
        text: "Gary / a marketing job / has / dedicated / 8am to 3 pm",
      },
      {
        id: 3,
        text: "Terry / a sales job / have / ambitious / 7am to 4pm",
      },
    ],
    answer: 1,
    points: 5,
  },

  {
    id: 11,
    question:
      "I ____ to the office yesterday. I stayed at home because I was ill.",
    options: [
      { id: 1, text: "go" },
      { id: 2, text: "didn’t go" },
      { id: 3, text: "went" },
      { id: 4, text: "did go" },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 12,
    question: "How ____ here? By bus or on foot?",
    options: [
      { id: 1, text: "got you" },
      { id: 2, text: "did you get" },
      { id: 3, text: "you got" },
      { id: 4, text: "did you got" },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 13,
    question: "At 8.00 a.m on Thursday Stevie ____ on a plane to Switzerland",
    options: [
      { id: 1, text: "is" },
      { id: 2, text: "will be" },
      { id: 3, text: "is going to be" },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 14,
    question: "On Friday evening he ____ in Switzerland.",
    options: [
      { id: 1, text: "will still be" },
      { id: 2, text: "is still going to be" },
      { id: 3, text: "still will be" },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 15,
    type: "image",
    image_URL: "/images/15.png",
    question: "What job did Grace apply for?",
    options: [
      { id: 1, text: "Sales manager." },
      { id: 2, text: "Human resources assistant." },
      { id: 3, text: "Head of sales." },
    ],
    answer: 1,
    points: 3,
  },
  {
    id: 16,
    related: true,
    question: "What do you need to have to receive a visitor's pass?",
    options: [
      { id: 1, text: "Your letter of recommendation." },
      { id: 2, text: "Your CV and references." },
      { id: 3, text: "Your ID." },
    ],
    answer: 3,
    points: 3,
  },
  {
    id: 17,
    question: "Can I call you? I've got some very ____ news for you!",
    options: [
      { id: 1, text: "exciting" },
      { id: 2, text: "excited" },
      { id: 3, text: "excite" },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 18,
    type: "video",
    video_URL: "https://www.youtube.com/embed/ENfUSe_sFPs",
    question: "Anita talked about a situation. It was about...",
    options: [
      {
        id: 1,
        text: "A time when she pulled a team together.",
      },
      {
        id: 2,
        text: "A time when she sent out a memo and nobody received it.",
      },
      {
        id: 3,
        text: "A time when she had a meeting but nobody went.",
      },
    ],
    answer: 1,
    points: 3,
  },
  {
    id: 19,
    related: true,
    question: "What happened after Anita sent out the memo?",
    options: [
      {
        id: 1,
        text: "She realized that she sent it to the wrong people.",
      },
      {
        id: 2,
        text: "She realized that no one had any idea.",
      },
      {
        id: 3,
        text: "She realized that it wasn’t necessary to send the memo.",
      },
    ],
    answer: 2,
    points: 3,
  },
  {
    id: 20,
    related: true,
    question: "How did Anita realize what happened?",
    options: [
      {
        id: 1,
        text: "Anita asked the manager about the confusion.",
      },
      {
        id: 2,
        text: "Anita received an e-mail from her team asking about the project plan.",
      },
      {
        id: 3,
        text: "A coworker told Anita that nobody received the project plan.",
      },
    ],
    answer: 3,
    points: 2,
  },
  {
    id: 21,
    related: true,
    question: "What did Anita decide?",
    options: [
      {
        id: 1,
        text: "Anita decided to talk with the manager about the situation.",
      },
      {
        id: 2,
        text: "Anita decided to have a meeting with her team about the project plan.",
      },
      {
        id: 3,
        text: "Anita decided to send another e-mail with the project plan.",
      },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 22,
    question: "I work in a ____ office just south of Barcelona.",
    options: [
      {
        id: 1,
        text: "branch",
      },
      {
        id: 2,
        text: "division",
      },
      {
        id: 3,
        text: "second",
      },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 23,
    question: "Where is Tom? He's ___ to meet a client.",
    options: [
      {
        id: 1,
        text: "been out",
      },
      {
        id: 2,
        text: "gone out",
      },
      {
        id: 3,
        text: "went",
      },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 24,
    question:
      "If Sara hadn’t taken time off for a long holiday, she ____ the general manager now.",
    options: [
      {
        id: 1,
        text: "might be",
      },
      {
        id: 2,
        text: "will be",
      },
      {
        id: 3,
        text: "could be",
      },
    ],
    answer: 3,
    points: 2,
  },
  {
    id: 25,
    question: "This month's import quota was really difficult to ____",
    options: [
      {
        id: 1,
        text: "figure in.",
      },
      {
        id: 2,
        text: "figure out.",
      },
      {
        id: 3,
        text: "figure to.",
      },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 26,
    question: "The company ____ in new technology this year.",
    options: [
      {
        id: 1,
        text: "did invest",
      },
      {
        id: 2,
        text: "has invested",
      },
      {
        id: 3,
        text: "has invited",
      },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 27,
    question: " I'd like to speak to John, but I can't ____ to him.",
    options: [
      {
        id: 1,
        text: "get through",
      },
      {
        id: 2,
        text: "get in",
      },
      {
        id: 3,
        text: "get on",
      },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 28,
    question: "I’ve been working on this project ____",
    options: [
      {
        id: 1,
        text: "long time.",
      },
      {
        id: 2,
        text: "for 3 months now.",
      },
      {
        id: 3,
        text: "since 3 months.",
      },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 29,
    question:
      "By this time tomorrow, the team leader ____ on the project budget for 24 hours.",
    options: [
      {
        id: 1,
        text: "will have working",
      },
      {
        id: 2,
        text: "will have been worked",
      },
      {
        id: 3,
        text: "will have been working",
      },
    ],
    answer: 3,
    points: 2,
  },
  {
    id: 30,
    question:
      "The CFO remembers ____ the report but he's not sure where he put it.",
    options: [
      {
        id: 1,
        text: "saving",
      },
      {
        id: 2,
        text: "saved",
      },
      {
        id: 3,
        text: "to save",
      },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 31,
    question:
      "At the meeting, Jack brought something really interesting ____ table.",
    options: [
      {
        id: 1,
        text: "from the",
      },
      {
        id: 2,
        text: "out of the",
      },
      {
        id: 3,
        text: "to the",
      },
    ],
    answer: 3,
    points: 2,
  },
  {
    id: 32,
    question:
      "I would like to discuss this issue with Jane, but she ____ answer the phone.",
    options: [
      {
        id: 1,
        text: "not",
      },
      {
        id: 2,
        text: "won’t",
      },
      {
        id: 3,
        text: "don’t",
      },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 33,
    type: "image",
    image_URL: "/images/33.png",
    question: "Why is the HR department sending this email?",
    options: [
      {
        id: 1,
        text: "To explain how something is done",
      },
      {
        id: 2,
        text: "To ask staff for some information",
      },
      {
        id: 3,
        text: "To tell managers about a problem",
      },
    ],
    answer: 1,
    points: 3,
  },
  {
    id: 34,
    type: "image",
    image_URL: "/images/34.png",
    question: "Why is the HR department sending this email?",
    options: [
      {
        id: 1,
        text: "deliver goods all over the country.",
      },
      {
        id: 2,
        text: "own a suitable vehicle for delivery.",
      },
      {
        id: 3,
        text: "be a specialist in food distribution.",
      },
    ],
    answer: 1,
    points: 3,
  },
  {
    id: 35,
    type: "video",
    video_URL: "https://www.youtube.com/embed/UNP03fDSj1U",
    question: "Choose the option that represents a summary of the video.",
    options: [
      {
        id: 1,
        text: "A few years ago, I felt like I was stuck in a rut. So I decided to try something new for 30 days. Last year I ended up hiking up Mount Kilimanjaro. I would never have been that adventurous after I started my 30 day challenges. There’s nothing wrong with big, difficult challenges. In fact, they’re a lot of fun. But they’re less likely to stick.",
      },
      {
        id: 2,
        text: "A few years ago, I felt like I was stuck in a rut. So I decided to try something new for 30 days. Last year I ended up hiking up Mount Kilimanjaro. I would never have been that adventurous before I started my 30 day challenges. There’s nothing wrong with big, crazy challenges. In fact, they’re a ton of fun. But they’re less likely to stick.",
      },
      {
        id: 3,
        text: "A few years ago, I felt like I was stuck in a rut. So I decided to try something new for 30 days. Last year I ended up hiking up Mount Kilimanjaro. I would have never been that adventurous as I started my 30 day challenges. There’s nothing wrong with big, strange challenges. In fact, they’re a bit of fun. But they’re less likely to stick.",
      },
    ],
    answer: 2,
    points: 6,
  },
  {
    id: 36,
    question:
      "Even though the President made the comments ____, they still appeared in the newspaper.",
    options: [
      {
        id: 1,
        text: "off the scale",
      },
      {
        id: 2,
        text: "off the record",
      },
      {
        id: 3,
        text: "off the cuff",
      },
    ],
    answer: 2,
    points: 2,
  },
  {
    id: 37,
    question:
      "If Henry ____ at the meeting, he wouldn’t have missed the announcement.",
    options: [
      {
        id: 1,
        text: "was",
      },
      {
        id: 2,
        text: "has been",
      },
      {
        id: 3,
        text: "had been",
      },
    ],
    answer: 3,
    points: 2,
  },
  {
    id: 38,
    question: "Yesterday, JBO ____ by OLR.",
    options: [
      {
        id: 1,
        text: "was taken over",
      },
      {
        id: 2,
        text: "were took over",
      },
      {
        id: 3,
        text: "taken over",
      },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 39,
    question: "I ____ the job, if the perks hadn't been so great.",
    options: [
      {
        id: 1,
        text: "wouldn’t have taken",
      },
      {
        id: 2,
        text: "wouldn’t have took",
      },
      {
        id: 3,
        text: "wouldn’t had took",
      },
    ],
    answer: 1,
    points: 2,
  },
  {
    id: 40,
    type: "image",
    image_URL: "/images/40.png",
    question: "Staff should tell Jane Fellows:",
    options: [
      {
        id: 1,
        text: "Which of the courses they have decided to do.",
      },
      {
        id: 2,
        text: "How many people have enrolled for the course.",
      },
      {
        id: 3,
        text: "If they are interested in doing the course.",
      },
    ],
    answer: 2,
    points: 3,
  },
  {
    id: 41,
    type: "image",
    image_URL: "/images/41.png",
    question: "Contact Neil Smith if you want to:",
    options: [
      {
        id: 1,
        text: "Obtain information about the company's prices.",
      },
      {
        id: 2,
        text: "Arrange a visit to the showroom.",
      },
      {
        id: 3,
        text: "Speak to a representative about special offers.",
      },
    ],
    answer: 1,
    points: 3,
  },
  null,
];
