import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as Logo } from "../../images/logo-digi.svg";

import { ReactComponent as Sync } from "../../images/sync.svg";

const location = window.location;
const endpoint = "https://api.ispeak.team/api/PasarelaMercadoPago/GetPayment/";

const Welcome = ({ screen, goForward, setUserHasBestPlan, setPulledInfo }) => {
  const thisElement = useRef(null);
  const [height, setHeight] = useState(568);

  //To calculate the size of each screen
  useEffect(() => {
    setHeight(thisElement.current.clientHeight);

    setTimeout(() => {
      setHeight(thisElement.current.clientHeight);
    }, 100);
  }, [thisElement]);

  //Plan
  const [planData, setPlanData] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const pullData = async () => {
    setLoading(true);
    setError(false);

    let id = location.pathname.slice(1);

    try {
      let response = await fetch(`${endpoint}${id}/1234`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      let data = await response.json();
      if (data.paymentInfo) {
        setPlanData(data.paymentInfo);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
      console.error(!id ? "Invalid ID" : error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (planData !== null) {
      if (planData.item === "Pro") {
        setUserHasBestPlan(true);
      } else {
        setUserHasBestPlan(false);
      }

      setPulledInfo({
        name: planData.nombre,
        lastname: planData.apellido,
        email: planData.email,
      });
      goForward();
    }
    //eslint-disable-next-line
  }, [planData]);

  return (
    <div
      className="screen welcome"
      style={{
        transform: `translateY(calc(${screen * -1} * ${height}px))`,
        zIndex: screen === 0 ? 100 : 0,
      }}
      ref={thisElement}
    >
      <div className="top-container">
        <div className="container">
          <Logo className="logo" />

          <span>Bienvenido a nuestro</span>
          <h1>Examen de nivel de Inglés</h1>
          <p>Has clic en el botón de abajo para iniciar</p>

          <button
            className="button blue"
            tabIndex={screen === 0 ? 0 : -1}
            onClick={pullData}
          >
            {!error ? "Iniciar" : "Error"} {loading && <Sync />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
